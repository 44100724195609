
$font-size-1:0.625rem; // 10px
$font-size-2:0.688rem; // 11px
$font-size-1-2:0.75rem; // 12px
$font-size-3:0.813rem; // 13px
$font-size-4:0.875rem; // 14px
$font-size-5:0.938rem; // 15px
$font-size-6:1rem; // 16px
$font-size-7:1.125rem; // 18px
$font-size-8:1.25rem; // 20px
$font-size-9:1.375rem; // 22px
$font-size-10:1.75rem; // 28
$font-size-11:2rem; // 32px
$font-size-12:2.25rem; // 36
$font-size-13:2.50rem;
$font-size-14:2.75rem;
$font-size-15:3rem; // 48rem
$font-size-16:3.25rem;
$font-size-17:3.438rem; // 55px

$font-weight-1:100;
$font-weight-2:200;
$font-weight-3:300;
$font-weight-4:400;
$font-weight-5:500;
$font-weight-6:600;
$font-weight-7:700;
$font-weight-8:800;
$font-weight-9:900;

$font-sizes: (
  1: $font-size-1,
  2: $font-size-2,
  '1-2': $font-size-1-2,
  3: $font-size-3,
  4: $font-size-4,
  5: $font-size-5,
  6: $font-size-6,
  7: $font-size-7,
  8: $font-size-8,
  9: $font-size-9,
  10: $font-size-10,
  11: $font-size-11,
  12: $font-size-12,
  13: $font-size-13,
  14: $font-size-14,
  15: $font-size-15,
  16: $font-size-16,
  17: $font-size-17,
);
