@import "src/styles/colors-variables";

.p-dialog {
  border-radius: 10px;
  overflow: hidden;
  width: 25rem;
  .p-dialog-header {
    display: flex;
    justify-content: center;
    background: $color-blue-2;
    color: white;
    padding: 5px;
  }
  .p-dialog-content {
    .p-confirm-dialog-message {
      margin-top: 1.25rem;
      text-align: center;
      width: 100%;
      color: #000;
    }
  }
  .p-dialog-footer {
    display: flex;
    align-items: center;
    justify-content: center;
    .dialog-button {
      border: 1px solid $color-blue-2;
      border-radius: 30px;
      padding:0.125rem 16px;
      font-weight: 600;
      min-width: 140px;
    text-align: center;
    }
    .p-confirm-dialog-reject {
      @extend .dialog-button;
      margin-right: 1.25rem;
      color: $color-blue-2;
      background-color: $color-white-1;
    }
    .p-confirm-dialog-accept {
      @extend .dialog-button;
      background-color: $color-blue-2;
    }
  }
}
