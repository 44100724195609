@import "src/styles/text-variables";
@import "src/styles/colors-variables";
.p-datatable {
  .p-datatable-table {
    margin-bottom: 1.25rem;
    border-collapse: separate !important;
    border-spacing: 0 0.625rem !important;
    color: $color-blue-2;

    .p-datatable-thead {
      tr {
        th {
          background-color: transparent;
          font-weight: normal;
          font-size: $font-size-6;
          border: unset;
          padding: 0 0.5rem;
          white-space: nowrap;
          color: $color-blue-2;
          text-transform: uppercase;

          &:nth-child(1) {
            padding-left: 1.4375rem;
          }
        }
      }
    }

    .p-datatable-tbody {
      tr {
        background-color: transparent;
        border: unset;
        td {
          border: unset;
          white-space: nowrap;
          background-color: $color-white-1;
          padding: 0.5rem 0.5rem;
          color: $color-blue-2;

          &:nth-child(1) {
            border-top-left-radius: 0.625rem;
            border-bottom-left-radius: 0.625rem;
            padding-left: 1.4375rem;
          }
          &:last-of-type {
            border-top-right-radius: 0.625rem;
            border-bottom-right-radius: 0.625rem;
          }
        }
      }
    }
  }
  .p-paginator {
    background-color: transparent;
  }
}
