.grid-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(12.5rem, 1fr));
  //grid-template-rows: repeat(auto-fit, minmax(10rem, 1fr));
  gap: 1.875rem;

  .grid-column-1 {
    grid-column: span 1;
  }

  .grid-column-2 {
    grid-column: span 2;
  }

  .grid-column-3 {
    grid-column: span 3;
  }
  .grid-column-4 {
    grid-column: span 4;
  }

  .grid-column-5 {
    grid-column: span 5;
  }

  .grid-column-6 {
    grid-column: span 6;
  }

  .grid-column-full {
    grid-column: 1 / -1;
  }
}

$columnSizes: (1, 2, 3, 4, 5, 6);

@each $columnSize in $columnSizes {
  .grid-container-column-#{$columnSize} {
    @extend .grid-container;
    grid-template-columns: repeat(auto-fit, minmax($columnSize * 100px, 1fr));
  }

  .grid-container-column-#{$columnSize}50 {
    @extend .grid-container;
    grid-template-columns: repeat(auto-fit, minmax($columnSize * 100px + 50px, 1fr));
  }

  .grid-container-row-#{$columnSize}50 {
    @extend .grid-container;
    grid-template-columns: repeat(auto-fit, minmax($columnSize * 100px + 50px, 1fr));
  }

  .grid-container-row-#{$columnSize} {
    @extend .grid-container;
    grid-template-rows: repeat(auto-fit, minmax($columnSize * 100px, 1fr));
  }

  .grid-container-#{$columnSize} {
    @extend .grid-container;
    grid-template-columns: repeat(auto-fit, minmax($columnSize * 100px, 1fr));
    //grid-template-rows: repeat(auto-fit, minmax($columnSize * 100px, 1fr));
  }

  .grid-container-#{$columnSize}50 {
    @extend .grid-container;
    grid-template-columns: repeat(auto-fit, minmax($columnSize * 100px + 50px, 1fr));
    //grid-template-rows: repeat(auto-fit, minmax($columnSize * 100px + 50px, 1fr));
  }
}
