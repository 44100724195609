
// generate a border-color, text, and background classes according to earch color in $colors
@each $colorType, $variants in $colors {
  @each $variant, $color in $variants {
    $colorVariant: '-' + $variant;
    @if($variant == 1) {
      // if it's first color variant the class will be ej: text-color-blue
      $colorVariant: '';
    }
    .text-#{$colorType}#{$colorVariant} {
      color: $color !important;
    }

    .bg-#{$colorType}#{$colorVariant} {
      background-color: $color !important;
    }

    .border-#{$colorType}#{$colorVariant} {
      border-color: $color !important;
    }
  }
}
