//BOOTSTRAP
@import "~bootstrap/scss/bootstrap";
//FONT FAMILY
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&family=Roboto:wght@300;400&display=swap');
// APP COLORS
@import 'styles/colors-variables';
@import './styles/color-classes.scss';
// APP FONT SIZES
@import 'styles/text-variables';
@import './styles/text-classes.scss';
//PRIME NG
@import "~primeicons/primeicons.css";
@import "~primeng/resources/themes/saga-purple/theme.css";
@import "~primeng/resources/primeng.min.css";
.pi {
  color: $color-blue-2 !important;
}
// red color for toast error
.p-toast .pi-times-circle {
  color: #73000c !important;
}
//GRID
@import './styles/grid.scss';

// CUSTOM
@import "styles/primeng/primeng-inputs";
@import "styles/primeng/dropdown-primeng";
@import "styles/primeng/table-primeng";
@import "styles/primeng/dialog-primeng.scss";

* {
  font-family: 'Poppins', sans-serif !important;
}

.pi {
  font-family: 'primeicons' !important;
}
.cursor-pointer {
  cursor: pointer;
}
body {
  font-size: 16px !important;
  background-color: $color-white-2;
  color: $color-blue-2;
}

.common-container {
  background-color: $color-white-1;
  border-radius: 0.375rem;
  padding: 25px 35px;
}
/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  // box-shadow: inset 0 0 5px grey; 
  background: #94949430;
  border-radius: 10px;
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: #949494; 
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #949494; 
}
.frmInput{
  border-radius: 0.5rem;
  border: 0.5px solid #001047;
  font-size: 0.875rem;
  padding: 0.3125rem 1rem;
}