// BLUE
$color-blue-1: #5637ff;
$color-blue-2: #001047;

// ROSE
$color-rose-1: #FF53A5;

// GRAY
$color-gray-1: #e6e6e6;

// BLACK
$color-black-1: #212629;

// WHITE
$color-white-1: #fff;
$color-white-2: #f3f3f3;

$prevue-gradient: linear-gradient(0deg, #ff53a4 50%, #5637ff 100%);

$color-hover: rgba(255, 255, 255, 0.2);

$colors: (
  "blue": (
    1: $color-blue-1,
    2: $color-blue-2,
  ),
  "rose": (
    1: $color-rose-1,
  ),
  "gray": (
    1: $color-gray-1,
  ),
  "black": (
    1: $color-black-1,
  ),
  "white": (
    1: $color-white-1,
    2: $color-white-2,
  ),
  "prevue-gradient": (
    1: $prevue-gradient,
  ),
  "color-hover": (
    1: $color-hover,
  ),
);
